* {
  padding: 0;
  margin: 0;
}

html {
  font-size: 12px;
}

h1 {
  padding: 1rem;
}

button:hover {
  cursor: pointer;
}

.black-button {
  background: #202020;
  color: #FFF;
  border: 1px solid #fff;
  padding: 13px 30px 10px 30px;
  border-radius: 24px;
}
.black-button:active {
  background: #3e3d3d;
}

.white-button {
  background: #FFF;
  color: #000;
  border: 1px solid #000;
  padding: 13px 30px 10px 30px;
  border-radius: 24px;
}

.white-button:disabled {
  background: rgba(239, 239, 239, 0.3);
  color: rgba(16, 16, 16, 0.3);
  border: 1px solid rgba(16, 16, 16, 0.3);
}

.white-button:active {
  background: #dbdada;
}

.input-textfield {
  border-radius: 4px;
  border-style: none;
  padding: 8px 12px;
  font-size: 1.1rem;
  width: 45%;
}

.input-textfield:active {
  border-style: none;
}

.input-select {
  display: flex;
  flex-grow: 1;
  background: url("arrowDown.svg") no-repeat right 12px center #fff;
  border-radius: 4px;
  background-size: 16px;
}

.input-select select {
  background: transparent;
  border-radius: 4px;
  border-style: none;
  padding: 8px 12px;
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
  display: flex;
  flex-grow: 1;
  font-size: 1.1rem;
}

.input-radio {
  display: flex;
  gap: 12px;
  align-items: center;
}

.input-radio div {
  font-size: 1.25rem;
}

.input-radio input[type="radio"] {
  height:15px;
  width: 15px;
}

.App {
  text-align: center;
  min-height: 100vh;
  background-color: #6e5e54;
  padding-top: 7vh;
  padding-bottom: 30vh;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-top: 85px;
}

.App-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  gap: 2rem;
  padding-left: 12px;
  padding-right: 12px;
}

@media (min-width: 400px) {
  .App-header {
    padding-top: 25px;
  }
  .App-body {
    font-size: calc(10px + 2vmin);
    gap: 2rem;
    padding-left: 0;
    padding-right: 0;
  }

  .input-textfield {
    width: unset;
  }
}

.App-link {
  color: white;
  font-weight: 300;
  font-size: 1.5rem;
}

.main-photo {
  border-radius: 10px;
  width: 80%;
  max-width: 700px;
}

.map-photo {
  border-radius: 10px;
  width: 80%;
  max-width: 700px;
}

.merege-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin: 0 2.4rem;
}

.merege-date {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  padding: 0.7rem 0;
  font-size: calc(1rem + 1vmin);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.merege-month {
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  padding: 0 1rem 0rem 1rem;
}

.merege-time {
  font-style: italic;
  padding-bottom: 0.8rem;
}

@media (min-width: 700px) {
  .App {
    text-align: center;
    min-height: 100vh;
    background-color: #6e5e54;
    padding-top: 10vh;
    padding-bottom: 30vh;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .App-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    gap: 4rem;
    max-width: "500px";
  }

  .merege-date {
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    padding: 1rem 0;
    font-size: calc(2rem + 1vmin);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }

  .merege-month {
    border-left: 4px solid #fff;
    border-right: 4px solid #fff;
    padding: 0 2rem 0rem 2rem;
  }
  
  .merege-details {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .App-link {
    color: white;
    font-weight: 300;
  }
  
  .main-photo {
    border-radius: 10px;
    width: 80%;
    max-width: 500px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* .navigation {
  width: 100%;
  height: 50px;
}

.navigation ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0 25px;
}
*/
.navigation ul li {
  list-style-type: none;
  text-align: start;
}

.hamburger {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  z-index: 10;
}

.burger {
  width: 2rem;
  height: 0.25rem;
  border-radius: 10px;
  background-color: black;
  transform-origin: 1px;
  transition: all 0.3s linear;
}

.hamburger {
  display: none;
}

@media (max-width: 767px) {
  .hamburger {
    display: fixed;
    padding-top: 10px;
    margin-left: 10px;
    z-index: 10;
  }
}

.burger1 {
  transform: rotate(0);
}
.burger1Open {
  transform: rotate(45deg);
}
.burger2 {
  transform: translateX(0);
  opacity: 1;
}
.burger2Open {
  transform: translateX(100%);
  opacity: 0;
}
.burger3 {
  transform: rotate(0);
}
.burger3Open {
  transform: rotate(-45deg);
}